export function prepareDateFields(data) {
  if (!data) { return }

  const fields = ['cardExpire', 'created', 'date', 'newsRead', 'timestamp', 'travelEndDate', 'travelStartDate', 'validTill']

  for (const field of fields) {
    if (data[field] && data[field].toDate) { data[field] = data[field].toDate() }
  }

  return data
}