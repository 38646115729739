import { createBatch, getFirestoreDoc } from '@/firebase/firestore'
import { setUserPersistance, getUid } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import localizeFilter from '@/filters/localize.filter'

export default {
  state: {
    tarifs: {
      'x55dPZIUMJJrs0OB9tro': {
        nameKey: 'TarifFree',
        limits: {
          fetchTransactions: 5,
          maxAccounts: 5,
          maxTags: 30,
          maxTransactions: 1000,
          tagsInTransaction: 1,
        }
      },
      'fa8W6IxGaUdWxACeI6ez': {
        nameKey: 'TarifBase',
        limits: {
          accountColor: true,
          creditLimit: true,
          defaultCurrency: true,
          fetchTransactions: 10,
          maxAccounts: 25,
          maxMoneyBoxes: 5,
          maxBanks: 10,
          maxCards: 25,
          maxTags: 100,
          maxTagsCollections: 5,
          maxTransactions: 10000,
          multiSessions: true,
          rates: true,
          tagsColors: true,
          tagAnalytics: true,
          tagComment: true,
          tagIcons: true,
          tagsInTransaction: 3,
          transactionComment: true,
          transfers: true
        }
      },
      'Y4qDkpxC7ZdROEVBPylj': {
        nameKey: 'TarifMax',
        limits: {
          accountColor: true,
          moneyBoxColor: true,
          creditLimit: true,
          defaultCurrency: true,
          emojiInTagName: true,
          fetchTransactions: 10,
          maxAccounts: 100,
          maxMoneyBoxes: 10,
          maxBanks: 30,
          maxCards: 50,
          maxTags: 1000,
          maxTagsCollections: 10,
          maxTransactions: 'unlimited',
          multiSessions: true,
          rates: true,
          uploadReceipts: true,
          setAppColor: true,
          setTransactionsSettings: true,
          spreads: true,
          tagAnalytics: true,
          tagComment: true,
          tagIcons: true,
          tagsColors: true,
          tagsInTransaction: 5,
          transactionComment: true,
          transfers: true
        }
      }
    },
    tarif: null,
    tarifsReset: 0
  },
  mutations: {
    setTarif(state, { tarifId, data }) {
      if (tarifId && data) {
        state.tarifs[tarifId] = data
      }
    },
    setUserTarif(state, tarif) {
      if (tarif) {
        state.tarif = tarif
      }
    },
    resetTarifs(state) {
      state.tarifsReset = Date.now()
    },
    clearInfo(state) {
      state.tarif = null
    }
  },
  actions: {
    async fetchUserTarif({ dispatch }) {
      const t = tracePerformance('fetchUserTarif')
      t.start()

      try {
        const tarif = await getFirestoreDoc('userTarif', getUid())
        await dispatch('tarifDataReceived', tarif)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchUserTarif', params: {} })
      } finally {
        t.stop()
      }
    },
    async fetchTarif({ commit, dispatch }, tarifId) {
      if (!tarifId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('fetchTarif')
      t.start()

      try {
        const data = await getFirestoreDoc('tarifs', tarifId)
        if (data) {
          await commit('setTarif', { tarifId, data })
          await commit('resetTarifs')
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchTarif', params: { tarifId } })
      } finally {
        t.stop()
      }
    },
    async tarifDataReceived({ commit, dispatch, getters }, data) {
      let oldTarif = null
      if (getters.tarif && getters.tarif.tarifId) {
        oldTarif = getters.tarif.tarifId + ''
      }

      if (data) {
        if ((data.validTill && +data.validTill < Date.now()) || (!data.validTill && data.tarifId !== 'x55dPZIUMJJrs0OB9tro')) {
          await dispatch('setFreeTarif')
        } else {
          await commit('setUserTarif', data)
        }
      } else {
        await commit('setUserTarif', {})
      }

      if (getters.tarif && getters.tarif.tarifId && !getters.tarifs[getters.tarif.tarifId]) {
        await dispatch('fetchTarif', getters.tarif.tarifId)
      }

      const tarif = await getters.tarif

      if (!getters.canUseLimit('multiSessions', false)) {
        await setUserPersistance('SESSION')
      }

      if (!getters.setTransactionsSettings) {
        if (getters.transactionsSettingsFiltered) {
          await commit('setLastFetchedTransaction', null)
          await commit('clearTransactions')
          await commit('setLoadedAll', { field: 'transactions', value: false })
        }

        await commit('clearTransactionsSettings')

        if (!Object.keys(getters.transactions).length && getters.canFetchTransactions && !getters.loadedAll('transactions')) {
          await dispatch('fetchTransactions')
        }
      }

      if (oldTarif && tarif && tarif.tarifId && tarif.tarifId !== oldTarif) {
        if (getters.loadedAll('tagsActive')) {
          await dispatch('fetchTags', true)
        }

        if (getters.loadedAll('tagsArchived')) {
          await dispatch('fetchTags', false)
        }

        if (!getters.getLimitNumber('maxTagsCollections')) {
          await commit('clearTagsCollections')
          await commit('setLoadedAll', { field: 'tagsCollections', value: false })
        } else if (getters.loadedAll('tagsCollections')) {
          await dispatch('fetchTagsCollections')
        }

        if (!getters.canUseSpreads) {
          await commit('clearSpreads')
        }

        if (!getters.getLimitNumber('maxMoneyBoxes')) {
          await commit('clearMoneyBoxes')
          await commit('setLoadedAll', { field: 'moneyBoxes', value: false })
          await commit('clearMoneyBoxesSort')
          await commit('clearMoneyBoxesFilters')
        } else if (getters.loadedAll('moneyBoxes')) {
          await dispatch('fetchMoneyBoxes')
        }

        if (!getters.getLimitNumber('maxBanks')) {
          await commit('clearBanks')
          await commit('setLoadedAll', { field: 'banks', value: false })
          await commit('clearBanksFilters')
          await commit('clearAccountsFilters')
        } else if (!getters.loadedAll('banks')) {
          await dispatch('fetchBanks')
        }

        if (!getters.getLimitNumber('maxCards')) {
          await commit('clearCards')
          await commit('setLoadedAll', { field: 'cardsArchived', value: false })
          await commit('clearCardsFilters')
          await commit('clearAccountsFilters')
        } else if (!getters.loadedAll('cardsActive')) {
          await dispatch('fetchCards', { active: true })
        }
      }
    },
    async setFreeTarif({ commit, dispatch }) {
      const t = tracePerformance('setFreeTarif')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)

        const tarifId = 'x55dPZIUMJJrs0OB9tro'

        const data = {
          paymentId: null,
          tarifId: tarifId,
          timestamp: syncTimestamp,
          validTill: null
        }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userTarif',
            id: getUid(),
            data,
            logAction: 'edited',
            logData: {
              creator: 'user',
              creatorId: getUid(),
              info: data
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('tarifDataReceived', data)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'setFreeTarif', params: {} })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    }
  },
  getters: {
    tarifs: (s, getters) => {
      if (getters.tarifsReset) {
        //
      }

      return s.tarifs
    },
    tarif: s => s.tarif,
    tarifsReset: s => s.tarifsReset,
    getLimit: (s, getters) => limitName => {
      let userTarif

      const tarif = getters.tarif
      if (tarif && tarif.tarifId) { userTarif = tarif.tarifId }

      let limit
      if (limitName && userTarif && getters.tarifs[userTarif] && getters.tarifs[userTarif].limits && getters.tarifs[userTarif].limits[limitName]) {
        limit = getters.tarifs[userTarif].limits[limitName]
      }
      return limit
    },
    countFetchLimit: (s, getters) => limitName => {
      if (!limitName) { return 0 }
      const limit = getters.getLimit(limitName)
      if (!limit) { return 0 }
      if (limit === 'unlimited') { return false }
      return limit
    },
    canFetchTransactions: (s, getters) => {
      if (getters.transactionsReset) {
        //
      }

      const limit = getters.getLimit('maxTransactions')
      if (!limit) { return false }
      if (limit !== 'unlimited') {
        const transactionsLength = Object.keys(getters.transactions).length
        if (transactionsLength < limit + getters.getLimit('fetchTransactions')) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    canUseLimit: (s, getters) => (limitName, trueForLessonSteps) => {
      if (trueForLessonSteps && getters.lessonStep) { return true }
      return getters.getLimit(limitName)
    },
    getLimitNumber: (s, getters) => (limitName) => {
      if (['maxBanks', 'maxCards'].includes(limitName) && getters.lessonStep) { return 5 }

      let limit = getters.getLimit(limitName)
      if (!limit) { limit = 0 }
      if (limit === 'unlimited') { limit = Number.MAX_SAFE_INTEGER }
      return limit
    },
    getAvailableLimitNumber: (s, getters) => (resetName, statsName, limitName) => {
      if (resetName && getters[resetName]) {
        //
      }

      if (!statsName || !limitName) { return 0 }

      const totalLength = getters.userStats(statsName)
      if (totalLength === null) { return 0 }

      const limit = getters.getLimitNumber(limitName)

      const canAdd = limit - totalLength

      if (canAdd > 0) { return canAdd }
      return 0
    }
  }
}