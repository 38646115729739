<template>
  <div class="ml-20 pt-5">
    <div class="h-20 text-lg text-gray-700 dark:text-gray-600">
      <p v-if="!online">
        {{ $filters.localizeFilter('InternetAccessRequired') }}
      </p>

      <p v-if="online">
        {{ $filters.localizeFilter('InternetAccessRestored') }}.
      </p>

      <p v-if="online">
        {{ $filters.localizeFilter('ReloadPage') }}.
      </p>
    </div>

    <div class="flex flex-row">
      <button
        v-if="userId"
        v-tooltip="{
          text: $filters.localizeFilter('LogoutTheApp'),
          padding: 1
        }"
        class="overflow-dots px-4 py-2 mr-4 text-center text-sm font-medium text-gray-700 dark:text-gray-600 rounded-lg border border-gray-100 dark:border-gray-50 focus:border-gray-200 bg-gray-50 focus:bg-gray-100 focus:outline-none shadow-md transition duration-500 ease-in-out transform hover:scale-105 select-none"
        @click.prevent.stop="logout()"
      >
        {{ $filters.localizeFilter('Logout') }}
      </button>

      <button
        v-if="online"
        class="overflow-dots px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-600 rounded-lg border border-gray-100 dark:border-gray-50 focus:border-gray-200 bg-gray-50 focus:bg-gray-100 focus:outline-none shadow-md transition duration-500 ease-in-out transform hover:scale-105 select-none"
        @click.prevent.stop="reload()"
      >
        {{ $filters.localizeFilter('PageReload') }}
      </button>
    </div>
  </div>
</template>

<script>
import { getUid } from '@/firebase/auth'

export default {
  name: 'OfflinePage',
  data: () => ({
    userId: null
  }),
  computed: {
    online() {
      return this.$store.getters.online
    }
  },
  created () {
    if (this.online) {
      this.$router.push({ name: 'Home' }).catch(() => { })
    }

    this.userId = getUid()
  },
  methods: {
    reload() {
      document.location.reload()
    },
    logout () {
      this.$store.dispatch('logoutAndGoToLogin', { from: 'logout-button-offline-page' })
    }
  }
}
</script>