import { getFirestoreDoc } from '@/firebase/firestore'
import { getUid } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'

export default {
  state: {
    userStats: {
      accounts: null,
      banks: null,
      cards: null,
      moneyBoxes: null,
      newsRead: null,
      spreads: null,
      tags: null,
      tagsCollections: null,
      transactions: null
    },
    lessonStep: null
  },
  mutations: {
    setUserStats(state, data) {
      if (data) {
        for (const key of Object.keys(data)) {
          state.userStats[key] = data[key]

          if (key === 'needsLesson') {
            state.lessonStep = 'start'
          }
        }
      }
    },
    increaseTotalNumberOf(state, { field, number }) {
      if (+number && field && state.userStats[field] !== undefined && state.userStats[field] !== null) {
        state.userStats[field] += +number
      }
    },
    setNewsRead(state, date) {
      if (date) { state.userStats.newsRead = date }
    },
    deleteFieldFromUserStats(state, field) {
      if (field && state.userStats[field] !== undefined) {
        delete state.userStats[field]
      }
    },
    setLessonStep(state, step) {
      state.lessonStep = step
    },
    clearTotals(state) {
      state.userStats = {
        accounts: null,
        banks: null,
        cards: null,
        moneyBoxes: null,
        spreads: null,
        tags: null,
        tagsCollections: null,
        transactions: null
      }
    },
    clearInfo(state) {
      state.userStats = {
        accounts: null,
        banks: null,
        cards: null,
        moneyBoxes: null,
        needsLesson: false,
        newsRead: null,
        spreads: null,
        tags: null,
        tagsCollections: null,
        transactions: null
      }
      state.lessonStep = null
    }
  },
  actions: {
    async fetchUserStats({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }

      const t = tracePerformance('fetchUserStats')
      t.start()

      try {
        const userStats = await getFirestoreDoc('userStats', getUid())
        if (userStats) {
          await commit('setUserStats', userStats)
          await commit('resetNews')
        }

        await commit('setLoadedAll', { field: 'userStats', value: true })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchUserStats', params: {} })
      } finally {
        t.stop()
      }
    }
  },
  getters: {
    userStats: (s) => (field) => {
      if (field) { return s.userStats[field] }
      return s.userStats
    },
    lessonStep: s => s.lessonStep
  }
}